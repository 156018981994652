import React from 'react';
import {Route, Switch} from 'react-router';
import AddressUpdateProvider from '../components/Providers/AddressUpdateProvider';
import FavoritesProvider from '../components/Providers/FavoritesProvider';
import Guard from './Auth/Guard';
import ListRoutes from './ListRoutes';

const Main : React.FC = () => {
    return (
        <Guard>
            <AddressUpdateProvider>
                <FavoritesProvider>
                    <Switch>
                        <Route path="/" exact component={ListRoutes}/>
                    </Switch>
                </FavoritesProvider>
            </AddressUpdateProvider>
        </Guard>
    );
};

export default Main;
