import makeStyles from '@material-ui/core/styles/makeStyles';
import DoneIcon from '@material-ui/icons/Done';
import TodoIcon from '@material-ui/icons/EmojiFlags';
import HasIssueIcon from '@material-ui/icons/Warning';
import React from 'react';
import {Address} from '../PreloadRoute';
import {Mode, useAuthData} from '../Providers/AuthProvider';

const useStyles = makeStyles(theme => ({
    done: {
        color: theme.palette.success.main,
    },
    hasIssue: {
        color: theme.palette.warning.main,
    },
}));

type Props = {
    address : Address;
    size ?: 'large';
    onIssueClick ?: () => void;
};

type AddressStatus = 'todo' | 'has-issue' | 'done';

export const addressStatus = (address : Address, mode : Mode) : AddressStatus => {
    if (mode === 'pickup' && address.pickupIssues !== null) {
        return 'has-issue';
    }

    if (mode === 'installation' && (
        address.installIssues.flagLeans
        || address.installIssues.missingSleeve
        || address.installIssues.missingMarker
        || address.installIssues.sleeveTooDeep
        || address.installIssues.photoProblem
        || address.installIssues.other !== null
        || address.installIssues.imageUrl !== null
    )) {
        return 'has-issue';
    }

    return (
        (mode === 'pickup' && !address.pickedUp)
        || (mode === 'installation' && address.flags.some(flag => flag.imageUrl === null))
    ) ? 'todo' : 'done';
};

const AddressStatusIcon : React.FC<Props> = ({address, size, onIssueClick} : Props) => {
    const classes = useStyles();
    const authData = useAuthData();
    const status = addressStatus(address, authData.mode);

    if (status === 'todo') {
        return <TodoIcon fontSize={size}/>;
    }

    if (status === 'has-issue') {
        return <HasIssueIcon className={classes.hasIssue} fontSize={size} onClick={onIssueClick}/>;
    }

    return <DoneIcon className={classes.done} fontSize={size}/>
};

export default AddressStatusIcon;
