import AppBar from '@material-ui/core/AppBar';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import {TransitionProps} from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import BackIcon from '@material-ui/icons/ArrowBack';
import ReportIssueIcon from '@material-ui/icons/BugReport';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import RefreshIcon from '@material-ui/icons/Refresh';
import React, {useState} from 'react';
import LoadingBackdrop from '../LoadingBackdrop';
import NotificationsIndicator from '../Notifications/NotificationsIndicator';
import {Address} from '../PreloadRoute';
import {useAuthData} from '../Providers/AuthProvider';
import {addressStatus} from '../Route/AddressStatusIcon';
import {AddressUpdate} from '../Route/RouteDialog';
import UpdateIndicator from '../UpdateIndicator';
import AddressDetails from './AddressDetails';
import ReportIssueDialog from './ReportIssueDialog';

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        flexGrow: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: 56,
    },
    bottomNavigation: {
        width: '100%',
        position: 'fixed',
        top: 'auto',
        bottom: 0,
    },
}));

type Props = {
    open : boolean;
    onClose : () => void;
    address : Address;
    fallbackRouteMapUrl : string | null;
    onNavigatePrevious : () => void;
    onNavigateNext : () => void;
    onUpdate : (addressId : number, update : AddressUpdate) => void;
    onRefresh : () => Promise<void>;
};

const transition = (
    props : TransitionProps & {children ?: React.ReactElement},
    ref : React.Ref<unknown>,
) => {
    return <Slide direction="left" ref={ref} {...props}/>;
};
const Transition = React.forwardRef(transition);

const AddressDialog : React.FC<Props> = ({
    open,
    onClose,
    address,
    fallbackRouteMapUrl,
    onNavigatePrevious,
    onNavigateNext,
    onUpdate,
    onRefresh,
} : Props) => {
    const classes = useStyles();
    const authData = useAuthData();
    const [navigateErrorDialogCallback, setNavigateErrorDialogCallback] = useState<(() => void) | null>(null);
    const [manualRefresh, setManualRefresh] = useState(false);
    const [reportIssueDialogOpen, setReportIssueDialogOpen] = useState(false);

    const handleNavigation = (callback : () => void) => {
        if (addressStatus(address, authData.mode) === 'todo') {
            // We have to wrap the state setter in another function, as otherwise the callback gets called directly…
            setNavigateErrorDialogCallback(() => callback);
            return;
        }

        callback();
    };

    const handleManualRefresh = async () => {
        setManualRefresh(true);
        await onRefresh();
        setManualRefresh(false);
    };

    return (
        <Dialog fullScreen open={open} TransitionComponent={Transition}>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose}>
                        <BackIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.dialogTitle}>
                        Address: {address.streetAddress}
                    </Typography>
                    <IconButton onClick={handleManualRefresh} color="inherit">
                        <RefreshIcon/>
                    </IconButton>
                    <NotificationsIndicator/>
                </Toolbar>
            </AppBar>

            <UpdateIndicator/>

            <Container className={classes.container} maxWidth="md">
                <AddressDetails
                    address={address}
                    fallbackRouteMapUrl={fallbackRouteMapUrl}
                    onUpdate={onUpdate}
                    onOpenIssue={() => setReportIssueDialogOpen(true)}
                />
            </Container>

            <LoadingBackdrop open={manualRefresh}/>

            <Paper className={classes.bottomNavigation} elevation={2}>
                <BottomNavigation showLabels>
                    <BottomNavigationAction
                        label="Previous"
                        icon={<NavigateBeforeIcon/>}
                        onClick={() => handleNavigation(onNavigatePrevious)}
                    />
                    <BottomNavigationAction
                        label="Report Issue"
                        icon={<ReportIssueIcon/>}
                        onClick={() => setReportIssueDialogOpen(true)}
                    />
                    <BottomNavigationAction
                        label="Next"
                        icon={<NavigateNextIcon/>}
                        onClick={() => handleNavigation(onNavigateNext)}
                    />
                </BottomNavigation>
            </Paper>

            <ReportIssueDialog
                open={reportIssueDialogOpen}
                onClose={() => setReportIssueDialogOpen(false)}
                address={address}
                onUpdate={onUpdate}
            />

            <Dialog
                fullWidth
                maxWidth="sm"
                open={navigateErrorDialogCallback !== null}
                onClose={() => setNavigateErrorDialogCallback(null)}
            >
                <DialogTitle>Unfinished Address</DialogTitle>
                {authData.mode === 'installation' ? (
                    <DialogContent>
                        <DialogContentText>
                            You have not yet collected a photo of each flag at this address. Please tap "Okay" to go
                            {' '}back and collect the photos.
                        </DialogContentText>
                        <DialogContentText>
                            If there's a problem and you can't get the photos, tap "Okay", then use "Report Issue" to
                            {' '}let us know.
                        </DialogContentText>
                    </DialogContent>
                ) : (
                    <DialogContent>
                        <DialogContentText>
                            You have not yet marked the checkbox for picking up all flags at this address. Please tap
                            {' '}"Okay" to go back and mark the checkbox.
                        </DialogContentText>
                        <DialogContentText>
                            If there's a problem collecting the flags, tap "Okay", then use "Report Issue" to let us
                            {' '}know.
                        </DialogContentText>
                    </DialogContent>
                )}
                <DialogActions>
                    <Button onClick={() => {
                        navigateErrorDialogCallback && navigateErrorDialogCallback();
                        setNavigateErrorDialogCallback(null);
                    }}>Skip for now</Button>
                    <Button onClick={() => setNavigateErrorDialogCallback(null)} color="secondary">Okay</Button>
                </DialogActions>
            </Dialog>
        </Dialog>
    );
};

export default AddressDialog;
