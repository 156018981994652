import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import {Address} from '../PreloadRoute';
import AddressStatusIcon from './AddressStatusIcon';

type Props = {
    address : Address;
    onClick : () => void;
};

const AddressListItem : React.FC<Props> = ({address, onClick} : Props) => (
    <ListItem button onClick={onClick}>
        <ListItemIcon>
            <AddressStatusIcon address={address}/>
        </ListItemIcon>
        <ListItemText
            primary={address.streetAddress}
            secondary={`${address.flags.length} flag${address.flags.length !== 1 ? 's' : ''}`}
        />
    </ListItem>
);

export default AddressListItem;
