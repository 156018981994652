import Button from '@material-ui/core/Button';
import {useSnackbar} from 'notistack';
import React, {useCallback} from 'react';

type LoadError = (name : string, retry : () => void) => void;

export const useLoadError = () : LoadError => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    return useCallback((name : string, retry : () => void) : void => {
        const snackbarKey = enqueueSnackbar(`Could not load ${name}`, {
            variant: 'error',
            persist: true,
            action: (
                <Button
                    color="inherit"
                    onClick={() => {
                        closeSnackbar(snackbarKey);
                        retry();
                    }}
                >
                    try again
                </Button>
            ),
        });
    }, [enqueueSnackbar, closeSnackbar]);
};
