import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import React, {MouseEvent} from 'react';
import {useFavorites} from '../Providers/FavoritesProvider';

const useStyles = makeStyles(theme => ({
    favorite: {
        color: theme.palette.primary.main,
    },
}));

type Props = {
    className ?: string;
    edge ?: 'start' | 'end' | false;
    routeId : number;
    onChange ?: (routeId : number, isFavorite : boolean) => void;
};

const FavoriteButton : React.FC<Props> = ({className, edge, routeId, onChange} : Props) => {
    const {favorites, addFavorite, removeFavorite} = useFavorites();
    const classes = useStyles();
    const isFavorite = favorites.includes(routeId);

    const toggleFavorite = (event : MouseEvent<HTMLElement>) => {
        if (onChange) {
            onChange(routeId, !isFavorite);
        }

        if (isFavorite) {
            removeFavorite(routeId);
        } else {
            addFavorite(routeId);
        }

        event.stopPropagation();
    };

    return (
        <IconButton onClick={toggleFavorite} className={className} edge={edge}>
            {isFavorite ? <FavoriteIcon className={classes.favorite}/> : <FavoriteBorderIcon/>}
        </IconButton>
    );
};

export default FavoriteButton;
