import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import {TransitionProps} from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import BackIcon from '@material-ui/icons/ArrowBack';
import RefreshIcon from '@material-ui/icons/Refresh'
import deepmerge from 'deepmerge';
import deepEqual from 'fast-deep-equal';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useLoadError} from '../LoadError';
import LoadingBackdrop from '../LoadingBackdrop';
import NotificationsIndicator from '../Notifications/NotificationsIndicator';
import {marshalRoute, Route, usePreloadRoute} from '../PreloadRoute';
import {RouteAddressUpdate, UpdateResult, useAddressUpdate} from '../Providers/AddressUpdateProvider';
import {apiEndpoint, useApiFetch, useAuthData} from '../Providers/AuthProvider';
import UpdateIndicator from '../UpdateIndicator';
import AddressList from './AddressList';
import {addressStatus} from './AddressStatusIcon';

const useStyles = makeStyles(() => ({
    dialogTitle: {
        flexGrow: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
}));

export type AddressUpdate = Omit<RouteAddressUpdate, 'routeId' | 'addressId'>;

type Props = {
    routeId : number;
    routeName : string;
    open : boolean;
    onClose : (addressesDone ?: number) => void;
};

const transition = (
    props : TransitionProps & {children ?: React.ReactElement},
    ref : React.Ref<unknown>,
) => {
    return <Slide direction="left" ref={ref} {...props}/>;
};
const Transition = React.forwardRef(transition);

const applyRouteAddressUpdate = (route : Route, update : RouteAddressUpdate) => {
    const address = route.addresses.find(address => address.id === update.addressId);

    if (!address) {
        return route;
    }

    const newAddress = {
        ...address,
        installIssues: {...address.installIssues},
    };

    if (update.flags) {
        const updatedFlags = update.flags;
        newAddress.flags = newAddress.flags.map(currentFlag => {
            const newImage = updatedFlags[currentFlag.id];
            return newImage ? {id: currentFlag.id, imageUrl:  'data:image/jpeg;base64,' + newImage} : currentFlag;
        });
    }

    if (update.installIssues !== undefined) {
        newAddress.installIssues = deepmerge(newAddress.installIssues, update.installIssues);

        if (update.installIssues.image) {
            newAddress.installIssues.imageUrl = 'data:image/jpeg;base64,' + newAddress.installIssues.imageUrl;
        }
    }

    if (update.pickupIssues !== undefined) {
        newAddress.pickupIssues = update.pickupIssues;
    }

    if (update.pickedUp !== undefined) {
        newAddress.pickedUp = update.pickedUp;
    }

    return {
        ...route,
        addresses: route.addresses.map(
            currentAddress => currentAddress.id === update.addressId ? newAddress : currentAddress
        ),
    };
};

const RouteDialog : React.FC<Props> = ({routeId, routeName, open, onClose} : Props) => {
    const classes = useStyles();
    const {mode} = useAuthData();
    const apiFetch = useApiFetch();
    const loadError = useLoadError();
    const [route, setRoute] = useState<Route | null>(null);
    const {subscribe, unsubscribe, queueUpdate, getUpdates} = useAddressUpdate();
    const [manualRefresh, setManualRefresh] = useState(false);
    const lastRoute = useRef<Route | null>(null);
    const preloadRoute = usePreloadRoute();

    const loadRoute = useCallback(async (ignoreLoadError = false, signal ?: AbortSignal) => {
        let response;

        try {
            response = await apiFetch(
                new URL(`/wp-json/flags_on_route/v1/routes/${routeId}`, apiEndpoint).toString(),
                {signal}
            );
        } catch (e) {
            response = null;
        }

        if (!response || !response.ok) {
            if (!ignoreLoadError) {
                loadError('route', loadRoute);
            }

            return;
        }

        const {data} = await response.json();

        if (signal?.aborted) {
            return;
        }

        let route = marshalRoute(data.route);
        preloadRoute(route.id, route);

        const addressUpdates = getUpdates(routeId);

        for (const update of addressUpdates) {
            route = applyRouteAddressUpdate(route, update);
        }

        if (lastRoute.current && deepEqual(lastRoute.current, route)) {
            return;
        }

        lastRoute.current = route;
        setRoute(route);
    }, [apiFetch, loadError, setRoute, routeId, getUpdates, preloadRoute]);

    useEffect(() => {
        if (!open) {
            return undefined;
        }

        loadRoute();

        const controller = new AbortController();
        const interval = window.setInterval(() => {
            loadRoute(true, controller.signal);
        }, 60 * 1000);

        return () => {
            controller.abort();
            window.clearInterval(interval);
        };
    }, [loadRoute, open]);

    useEffect(() => {
        const subscriber = async (update : UpdateResult) => {
            if (!route) {
                return;
            }

            const address = route.addresses.find(address => address.id === update.addressId);

            if (!address) {
                return;
            }

            const newAddress = {...address};

            if (update.flags) {
                const updatedFlags = update.flags;
                newAddress.flags = newAddress.flags.map(currentFlag => {
                    const newImageUrl = updatedFlags[currentFlag.id];
                    return newImageUrl ? {id: currentFlag.id, imageUrl: newImageUrl} : currentFlag;
                });
            }

            if (update.installIssuesImageUrl) {
                newAddress.installIssues = {
                    ...newAddress.installIssues,
                    imageUrl: update.installIssuesImageUrl,
                };
            }

            setRoute({
                ...route,
                addresses: route.addresses.map(
                    currentAddress => currentAddress.id === update.addressId ? newAddress : currentAddress
                ),
            });
        };

        subscribe(routeId, subscriber);
        return () => unsubscribe(routeId, subscriber);
    }, [route, setRoute, routeId, subscribe, unsubscribe]);

    const performUpdate = useCallback((addressId : number, update : AddressUpdate) => {
        if (!route) {
            return;
        }

        const routeAddressUpdate = {routeId: routeId, addressId, ...update};
        setRoute(applyRouteAddressUpdate(route, routeAddressUpdate));
        queueUpdate(addressId, routeAddressUpdate);
    }, [route, setRoute, queueUpdate, routeId]);

    const handleManualRefresh = async () => {
        setManualRefresh(true);
        await loadRoute();
        setManualRefresh(false);
    };

    const handleClose = useCallback(() => {
        if (!route) {
            return onClose();
        }

        onClose(route.addresses.filter(address => addressStatus(address, mode) === 'done').length);
    }, [onClose, route, mode]);

    return (
        <Dialog fullScreen open={open} TransitionComponent={Transition}>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose}>
                        <BackIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.dialogTitle}>
                        Route {routeName}
                    </Typography>
                    <IconButton onClick={handleManualRefresh} color="inherit">
                        <RefreshIcon/>
                    </IconButton>
                    <NotificationsIndicator/>
                </Toolbar>
            </AppBar>

            <UpdateIndicator/>

            <AddressList
                route={route}
                onUpdate={performUpdate}
                onRefresh={loadRoute}
                onClose={handleClose}
            />

            <LoadingBackdrop open={manualRefresh}/>
        </Dialog>
    );
};

export default RouteDialog;
