import {Dialog} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, {useCallback, useEffect, useState} from 'react';
import InlineLoadingIndicator from './InlineLoadingIndicator';
import {useLoadError} from './LoadError';
import PaddedContainer from './PaddedContainer';
import Paragraphs from './Paragraphs';
import {apiEndpoint, useApiFetch} from './Providers/AuthProvider';

type Props = {
    onClose : () => void;
    title : string;
    open : boolean;
    path : string;
    property : string;
};

const RemoteTextDialog : React.FC<Props> = ({onClose, title, open, path, property} : Props) => {
    const apiFetch = useApiFetch();
    const loadError = useLoadError();
    const [text, setText] = useState<string | null>(null);

    const loadText = useCallback(async () => {
        let response;

        try {
            response = await apiFetch(new URL(path, apiEndpoint).toString());

            if (response.status === 403) {
                return;
            }
        } catch (e) {
            response = null;
        }

        if (!response || !response.ok) {
            loadError('data', loadText);
            return;
        }

        const {data} = await response.json();
        setText(data[property]);
    }, [apiFetch, path, loadError, setText, property]);

    useEffect(() => {
        if (!open || text) {
            return;
        }

        loadText();
    }, [open, text, loadText]);

    return (
        <Dialog open={open} onClose={onClose} fullScreen>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6">
                        {title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <PaddedContainer>
                {!text ? (
                    <InlineLoadingIndicator/>
                ) : (
                    <Paragraphs text={text}/>
                )}
            </PaddedContainer>
        </Dialog>
    );
};

export default RemoteTextDialog;
