import deepmerge from 'deepmerge';
import React, {createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {apiEndpoint, useApiFetch} from './AuthProvider';

export type RouteAddressUpdate = {
    routeId : number;
    addressId : number;
    flags ?: Record<string, string | null>;
    installIssues ?: Partial<{
        flagLeans : boolean;
        missingSleeve : boolean;
        missingMarker : boolean;
        sleeveTooDeep : boolean;
        photoProblem : boolean;
        other : string | null;
        image : string | null;
    }>;
    pickupIssues ?: string | null;
    pickedUp ?: boolean;
};

export type UpdateResult = {
    addressId : number;
    flags ?: Record<string, string>;
    installIssuesImageUrl ?: string;
};

type Subscriber = (update : UpdateResult) => void;

type AddressUpdateContext = {
    running : boolean;
    queueUpdate : (addressId : number, update : RouteAddressUpdate) => void;
    subscribe : (routeId : number, subscriber : Subscriber) => void;
    unsubscribe : (routeId : number, subscriber : Subscriber) => void;
    getUpdates : (routeId : number) => RouteAddressUpdate[];
};

const addressUpdateContext = createContext<AddressUpdateContext | null>(null);

type Props = {
    children ?: ReactNode;
};

const AddressUpdateProvider : React.FC<Props> = ({children} : Props) => {
    const apiFetch = useApiFetch();
    const [running, setRunning] = useState(false);
    const updateQueues = useRef(new Map<number, RouteAddressUpdate>());
    const runningUpdates = useRef(new Map<number, RouteAddressUpdate>());
    const subscribers = useRef(new Map<number, Subscriber[]>());

    const subscribe = useCallback((routeId : number, subscriber : Subscriber) => {
        const existingSubscribers = subscribers.current.get(routeId);
        subscribers.current.set(routeId, existingSubscribers ? [...existingSubscribers, subscriber] : [subscriber]);
    }, []);

    const unsubscribe = useCallback((routeId : number, subscriber : Subscriber) => {
        const existingSubscribers = subscribers.current.get(routeId);

        if (!existingSubscribers) {
            return;
        }

        subscribers.current.set(
            routeId,
            existingSubscribers.filter(currentSubscriber => currentSubscriber !== subscriber)
        );
    }, []);

    const saveUpdateQueue = () => {
        const map = new Map([...updateQueues.current.entries()]);

        for (const [addressId, updates] of runningUpdates.current.entries()) {
            const queuedUpdates = map.get(addressId);
            map.set(addressId, queuedUpdates ? deepmerge(updates, queuedUpdates) : updates);
        }

        localStorage.setItem('addressUpdateQueues', JSON.stringify([...map.entries()]));
    };

    const performUpdate = useCallback(async (addressId : number) => {
        if (runningUpdates.current.has(addressId)) {
            if (runningUpdates.current.size === 0) {
                setRunning(false);
            }

            return;
        }

        const update = updateQueues.current.get(addressId);

        if (!update) {
            if (runningUpdates.current.size === 0) {
                setRunning(false);
            }

            return;
        }

        setRunning(true);
        runningUpdates.current.set(addressId, update);
        updateQueues.current.delete(addressId);

        let response;

        try {
            response = await apiFetch(
                new URL(`/wp-json/flags_on_route/v1/address/${addressId}`, apiEndpoint).toString(),
                {
                    method: 'PATCH',
                    body: JSON.stringify({
                        flags: !update.flags
                            ? undefined
                            : Object.entries(update.flags).map(([id, image]) => ({id, image})),
                        installIssues: update.installIssues,
                        pickupIssues: update.pickupIssues,
                        pickedUp: update.pickedUp,
                    }),
                }
            );
        } catch (e) {
            response = null;
        }

        if (!response || !response.ok) {
            const existingUpdate = updateQueues.current.get(addressId);
            updateQueues.current.set(addressId, existingUpdate ? deepmerge(existingUpdate, update) : update);
            saveUpdateQueue();
            runningUpdates.current.delete(addressId);

            if (!response || response.status !== 403) {
                window.setTimeout(() => performUpdate(addressId), 1000 * (10 + Math.random() * 5));
            }
            return;
        }

        runningUpdates.current.delete(addressId);
        performUpdate(addressId);
        saveUpdateQueue();

        const currentSubscribers = subscribers.current.get(update.routeId);

        if (currentSubscribers) {
            const {data} = await response.json();
            const updateResult : UpdateResult = {addressId: addressId};

            if (data.flags) {
                updateResult.flags = Object.fromEntries(
                    data.flags.map(({id, image} : {id : string; image : string}) => [id, image])
                );
            }

            if (data.installIssuesImage) {
                updateResult.installIssuesImageUrl = data.installIssuesImage;
            }

            if (updateResult.flags || updateResult.installIssuesImageUrl) {
                currentSubscribers.map(subscriber => subscriber(updateResult));
            }
        }
    }, [apiFetch, setRunning]);

    useEffect(() => {
        const rawUpdateQueues = localStorage.getItem('addressUpdateQueues');

        if (!rawUpdateQueues) {
            return;
        }

        updateQueues.current = new Map(JSON.parse(rawUpdateQueues));

        for (const addressId of updateQueues.current.keys()) {
            performUpdate(addressId);
        }
    }, [performUpdate]);

    const queueUpdate = useCallback((addressId : number, update : RouteAddressUpdate) => {
        const existingUpdate = updateQueues.current.get(addressId);
        updateQueues.current.set(addressId, existingUpdate ? deepmerge(existingUpdate, update) : update);
        saveUpdateQueue();
        performUpdate(addressId);
    }, [performUpdate]);

    const getUpdates = useCallback((routeId : number) => {
        const queued = [...updateQueues.current.values()].filter(update => update.routeId === routeId);
        const running = [...runningUpdates.current.values()].filter(update => update.routeId === routeId);
        const addressIds = new Set<number>();

        queued.map(update => addressIds.add(update.addressId));
        running.map(update => addressIds.add(update.addressId));

        return [...addressIds.values()].map(addressId => {
            const queued = updateQueues.current.get(addressId);
            const running = updateQueues.current.get(addressId);

            if (queued && running) {
                return deepmerge(queued, running);
            } else if (queued) {
                return queued;
            } else if (running) {
                return running;
            }

            throw new Error('Unexpected state');
        });
    }, []);

    const context = useMemo(() => ({
        running,
        queueUpdate,
        subscribe,
        unsubscribe,
        getUpdates,
    }), [running, queueUpdate, subscribe, unsubscribe, getUpdates]);

    return (
        <addressUpdateContext.Provider value={context}>
            {children}
        </addressUpdateContext.Provider>
    );
};

export const useAddressUpdate = () : AddressUpdateContext => {
    const context = useContext(addressUpdateContext);

    if (!context) {
        throw new Error('Context was used outside of provider');
    }

    return context;
};

export default AddressUpdateProvider;
