import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React, {ReactNode} from 'react';
import NotificationsSnackbar from '../../components/Notifications/NotificationsSnackbar';
import {useAuth} from '../../components/Providers/AuthProvider';
import NotificationsProvider from '../../components/Providers/NotificationsProvider';
import AcceptTerms from './AcceptTerms';
import Unauthorized from './Unauthorized';

type Props = {
    children : ReactNode;
};

const DefaultAppBar = () => (
    <AppBar position="static">
        <Toolbar>
            <Typography variant="h6">I Want A Flag</Typography>
        </Toolbar>
    </AppBar>
);

const Guard : React.FC<Props> = ({children} : Props) => {
    const auth = useAuth();

    if (!auth.isAuthenticated) {
        return (
            <>
                <DefaultAppBar/>
                <Unauthorized/>
            </>
        );
    }

    if (!auth.data.user.termsAccepted) {
        return (
            <>
                <DefaultAppBar/>
                <AcceptTerms/>
            </>
        );
    }

    return (
        <NotificationsProvider>
            <NotificationsSnackbar/>
            {children}
        </NotificationsProvider>
    );
};

export default Guard;
