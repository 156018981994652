import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React, {useEffect, useState} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {BrowserRouter} from 'react-router-dom';
import PaddedContainer from './components/PaddedContainer';
import AuthProvider from './components/Providers/AuthProvider';
import ErrorFallback from './pages/ErrorFallback';
import Main from './pages/Main';
import * as serviceWorker from './serviceWorker';

const updateServiceWorker = (registration : ServiceWorkerRegistration) => {
    const {waiting} = registration;

    if (!waiting) {
        window.location.reload();
        return;
    }

    waiting.postMessage({action: 'skipWaiting'});
    waiting.addEventListener('statechange', e => {
        const target = e.target as ServiceWorker;

        if (target.state === 'activated') {
            window.location.reload();
        }
    });
};

const App : React.FC = () => {
    const [updatedRegistration, setUpdatedRegistration] = useState<ServiceWorkerRegistration | null>(null);

    useEffect(() => {
        serviceWorker.register({
            onUpdate: setUpdatedRegistration
        });
    }, []);

    if (updatedRegistration) {
        return (
            <>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6">I Want A Flag</Typography>
                    </Toolbar>
                </AppBar>

                <PaddedContainer>
                    <Typography gutterBottom>
                        A new version of the app is available.
                    </Typography>

                    <Button
                        onClick={() => updateServiceWorker(updatedRegistration)}
                        variant="contained"
                        color="secondary"
                    >
                        Update
                    </Button>
                </PaddedContainer>
            </>
        );
    }

    return (
        <BrowserRouter>
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => window.location.reload()}
            >
                <AuthProvider>
                    <Main/>
                </AuthProvider>
            </ErrorBoundary>
        </BrowserRouter>
    );
};

export default App;
