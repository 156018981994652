import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import PhotoIcon from '@material-ui/icons/Photo';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import classNames from 'classnames';
import ImageBlobReduce from 'image-blob-reduce';
import React, {ChangeEvent, useMemo, useState} from 'react';
import LoadingBackdrop from '../LoadingBackdrop';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        display: 'block',
    },
    placeholder: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 250,
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.grey[400],
    },
    photoContainer: {
        overflow: 'hidden',
    },
    photo: {
        display: 'block',
        width: '100%',
    },
    photoButton: {
        position: 'absolute',
        right: theme.spacing(2),
        bottom: theme.spacing(2),
    },
}));

type Props = {
    className ?: string;
    title : string;
    imageUrl : string | null;
    onUpdate : (image : string) => void;
};

const imageBlobReduce = new ImageBlobReduce();
let idSequence = 0;

const Photo : React.FC<Props> = ({className, title, imageUrl, onUpdate} : Props) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const id = useMemo(() => `photo-upload-${idSequence++}`, []);

    const handleFileSelect = async (event : ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || !event.target.files[0]) {
            return;
        }

        setLoading(true);
        const file = event.target.files[0];
        const canvas = await imageBlobReduce.toCanvas(file, {max: 640});
        const dataUrl = canvas.toDataURL('image/jpeg', 0.8);
        onUpdate(dataUrl.replace(/^data:image\/jpeg;base64,/, ''));
        setLoading(false);
    };

    return (
        <>
            <label htmlFor={id} className={classNames(classes.root, className)}>
                <Fab color="primary" component="span" size="small" className={classes.photoButton}>
                    <PhotoCameraIcon/>
                </Fab>

                {imageUrl ? (
                    <Paper className={classes.photoContainer}>
                        <img src={imageUrl} alt={title} className={classes.photo}/>
                    </Paper>
                ) : (
                    <Paper className={classes.placeholder} variant="outlined">
                        <PhotoIcon fontSize="large"/>
                    </Paper>
                )}
            </label>

            <input
                type="file"
                accept="image/*"
                id={id}
                style={{display: 'none'}}
                capture="environment"
                onChange={handleFileSelect}
            />

            <LoadingBackdrop open={loading}/>
        </>
    );
};

export default Photo;
