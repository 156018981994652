import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TermsOfServiceIcon from '@material-ui/icons/Description';
import HelpIcon from '@material-ui/icons/Help';
import SignOutIcon from '@material-ui/icons/PowerSettingsNew';
import React, {useState} from 'react';
import {useAuthActions, useAuthData} from './Providers/AuthProvider';
import RemoteTextDialog from './RemoteTextDialog';

const useStyles = makeStyles(theme => ({
    drawerHeader: {
        padding: theme.spacing(2),
    },
    displayName: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    emailAddress: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
}));

type Props = {
    onSelect : () => void;
};

const NavigationDrawer : React.FC<Props> = ({onSelect} : Props) => {
    const classes = useStyles();
    const authData = useAuthData();
    const {signOut} = useAuthActions();
    const [helpDialogOpen, setHelpDialogOpen] = useState(false);
    const [termsDialogOpen, setTermsDialogOpen] = useState(false);

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6">
                        I Want A Flag
                    </Typography>
                </Toolbar>
            </AppBar>

            <div className={classes.drawerHeader}>
                <Typography className={classes.displayName}>{authData.user.displayName}</Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classes.displayName}
                >
                    {authData.user.emailAddress}
                </Typography>
            </div>

            <Divider/>

            <List disablePadding>
                <ListItem button onClick={() => {
                    onSelect();
                    setHelpDialogOpen(true);
                }}>
                    <ListItemIcon><HelpIcon/></ListItemIcon>
                    <ListItemText>Help</ListItemText>
                </ListItem>
                <ListItem button onClick={() => {
                    onSelect();
                    setTermsDialogOpen(true);
                }}>
                    <ListItemIcon><TermsOfServiceIcon/></ListItemIcon>
                    <ListItemText>Terms of Service</ListItemText>
                </ListItem>
                <Divider/>
                <ListItem button onClick={() => {
                    onSelect();
                    signOut();
                }}>
                    <ListItemIcon><SignOutIcon/></ListItemIcon>
                    <ListItemText>Sign Out</ListItemText>
                </ListItem>
            </List>

            <RemoteTextDialog
                onClose={() => setHelpDialogOpen(false)}
                title="Help"
                open={helpDialogOpen}
                path="/wp-json/flags_on_route/v1/read-help-message"
                property="helpText"
            />
            <RemoteTextDialog
                onClose={() => setTermsDialogOpen(false)}
                title="Terms of Service"
                open={termsDialogOpen}
                path="/wp-json/flags_on_route/v1/read-tou"
                property="touText"
            />
        </>
    );
};

export default NavigationDrawer;
