import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import InlineLoadingIndicator from '../components/InlineLoadingIndicator';
import {useLoadError} from '../components/LoadError';
import LoadingBackdrop from '../components/LoadingBackdrop';
import {apiEndpoint, useApiFetch} from '../components/Providers/AuthProvider';
import {useFavorites} from '../components/Providers/FavoritesProvider';
import RouteListItem from '../components/Route/RouteListItem';
import UpdateIndicator from '../components/UpdateIndicator';
import UserAppBar from '../components/UserAppBar';

export type Route = {
    id : number;
    name : string;
    addressesDone : number;
    totalAddresses : number;
    totalFlags : number;
};

type RouteFilter = 'all' | 'complete' | 'incomplete';

const completeFilter = (route : Route) => route.addressesDone >= route.totalAddresses;
const incompleteFilter = (route : Route) => route.addressesDone < route.totalAddresses;

const useStyles = makeStyles(() => ({
    spacer: {
        flexGrow: 1,
    },
}));

const numberFormatter = new Intl.NumberFormat();

const ListRoutes : React.FC = () => {
    const apiFetch = useApiFetch();
    const loadError = useLoadError();
    const classes = useStyles();
    const {loadFavorites, favorites} = useFavorites();
    const [routes, setRoutes] = useState<Route[] | null>(null);
    const [filterMenuAnchor, setFilterMenuAnchor] = useState<HTMLElement | null>(null);
    const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);
    const [routeFilter, setRouteFilter] = useState<RouteFilter>('all');
    const [manualRefresh, setManualRefresh] = useState(false);

    const loadRoutes = useCallback(async () => {
        let response;

        try {
            response = await apiFetch(new URL('/wp-json/flags_on_route/v1/routes', apiEndpoint).toString());

            if (response.status === 403) {
                return;
            }
        } catch (e) {
            response = null
        }

        if (!response || !response.ok) {
            loadError('routes', loadRoutes);
            return;
        }

        const {data} = await response.json() as {
            data : {
                routes : Array<{
                    id : number;
                    routeName : string;
                    addressDone : number;
                    addressCount : number;
                    totalFlags : number;
                }>;
            };
        };

        setRoutes(data.routes.map(route => ({
            id: route.id,
            name: route.routeName,
            addressesDone: route.addressDone,
            totalAddresses: route.addressCount,
            totalFlags: route.totalFlags,
        })));
    }, [apiFetch, loadError, setRoutes]);

    useEffect(() => {
        loadRoutes();
    }, [loadRoutes]);

    useEffect(() => {
        if (routes) {
            loadFavorites(routes.map(route => route.id));
        }
    }, [routes, loadFavorites]);

    const handleUnfavorite = useCallback(() => {
        if (favorites.length === 1) {
            setShowOnlyFavorites(false);
        }
    }, [favorites, setShowOnlyFavorites]);

    const handleManualRefresh = useCallback(async () => {
        setManualRefresh(true);
        await loadRoutes();
        setManualRefresh(false);
    }, [loadRoutes]);

    const filteredRoutes = useMemo(() => {
        if (!routes) {
            return null;
        }

        const filteredRoutes = routeFilter === 'all' ? routes : routes.filter(
            routeFilter === 'complete' ? completeFilter : incompleteFilter
        );

        if (!showOnlyFavorites) {
            return filteredRoutes;
        }

        return routes.filter(route => favorites.includes(route.id));
    }, [routes, routeFilter, showOnlyFavorites, favorites]);

    const handleClose = useCallback((routeId : number, addressesDone ?: number) => {
        if (!routes || addressesDone === undefined) {
            return;
        }

        setRoutes(routes.map(route => {
            if (route.id !== routeId) {
                return route;
            }

            return {...route, addressesDone};
        }));
    }, [routes, setRoutes]);

    return (
        <>
            <UserAppBar action={(
                <IconButton onClick={handleManualRefresh} color="inherit">
                    <RefreshIcon/>
                </IconButton>
            )}/>

            <UpdateIndicator/>

            <Container disableGutters maxWidth="md">
                {!filteredRoutes ? (
                    <InlineLoadingIndicator/>
                ) : (
                    <>

                        <Toolbar>
                            <Button
                                startIcon={<FilterListIcon/>}
                                onClick={event => setFilterMenuAnchor(event.currentTarget)}
                            >
                                {
                                    routeFilter === 'all'
                                        ? 'All'
                                        : routeFilter === 'complete' ? 'Completed' : 'Incomplete'
                                } routes
                            </Button>
                            <div className={classes.spacer}/>
                            <Tooltip title="Show only favorites">
                                <IconButton onClick={() => setShowOnlyFavorites(!showOnlyFavorites)} edge="end">
                                    {showOnlyFavorites ? <FavoriteIcon/> : <FavoriteBorderIcon/>}
                                </IconButton>
                            </Tooltip>

                            <Menu
                                anchorEl={filterMenuAnchor}
                                keepMounted
                                open={Boolean(filterMenuAnchor)}
                                onClose={() => setFilterMenuAnchor(null)}
                            >
                                <MenuItem onClick={() => {
                                    setRouteFilter('all');
                                    setFilterMenuAnchor(null);
                                }}>All routes</MenuItem>
                                <MenuItem onClick={() => {
                                    setRouteFilter('complete');
                                    setFilterMenuAnchor(null);
                                }}>Completed routes</MenuItem>
                                <MenuItem onClick={() => {
                                    setRouteFilter('incomplete');
                                    setFilterMenuAnchor(null);
                                }}>Incomplete routes</MenuItem>
                                <Divider/>
                                <MenuItem onClick={() => {
                                    setShowOnlyFavorites(!showOnlyFavorites);
                                    setFilterMenuAnchor(null);
                                }}>
                                    <ListItemIcon>
                                        {showOnlyFavorites ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
                                    </ListItemIcon>
                                    <ListItemText primary="Only favorites"/>
                                </MenuItem>
                            </Menu>
                        </Toolbar>

                        <Paper square>
                            <List>
                                {filteredRoutes.map(route => (
                                    <RouteListItem
                                        key={route.id}
                                        route={route}
                                        onClose={handleClose}
                                        onUnfavorite={handleUnfavorite}
                                    />
                                ))}
                            </List>
                        </Paper>

                        <Box m={2}>
                            <Typography variant="body2">
                                Total # of flags: {numberFormatter.format(
                                    filteredRoutes.reduce((total, route) => total + route.totalFlags, 0)
                                )}
                            </Typography>
                        </Box>
                    </>
                )}
            </Container>

            <LoadingBackdrop open={manualRefresh}/>
        </>
    );
};

export default ListRoutes;
