import React, {createContext, Dispatch, ReactNode, useCallback, useContext, useMemo, useReducer} from 'react';

type Favorites = number[];

type LoadFavoriteAction = {
    type : 'LOAD_FAVORITES';
    payload : {
        routeIds : number[];
    };
};

type AddFavoriteAction = {
    type : 'ADD_FAVORITE';
    payload : {
        routeId : number;
    };
};

type RemoveFavoriteAction = {
    type : 'REMOVE_FAVORITE';
    payload : {
        routeId : number;
    };
};

type Action = LoadFavoriteAction | AddFavoriteAction | RemoveFavoriteAction;

const favoritesReducer = (state : Favorites, action : Action) : Favorites => {
    if (action.type === 'LOAD_FAVORITES') {
        const {routeIds} = action.payload;
        const storedFavorites = localStorage.getItem('favorites');

        if (!storedFavorites) {
            return state;
        }

        const newState = JSON.parse(storedFavorites).filter((routeId : number) => routeIds.includes(routeId));
        localStorage.setItem('favorites', JSON.stringify(newState));
        return newState;
    }

    const {routeId} = action.payload;
    let newState;

    switch (action.type) {
    case 'ADD_FAVORITE':
        newState = [...state, routeId];
        break;

    case 'REMOVE_FAVORITE':
        newState = state.filter(currentRouteId => currentRouteId !== routeId);
        break;
    }

    if (newState.length === 0) {
        localStorage.removeItem('favorites');
    } else {
        localStorage.setItem('favorites', JSON.stringify(newState));
    }

    return newState;
};

const favoritesContext = createContext<Favorites>([]);
const favoritesDispatchContext = createContext<Dispatch<Action> | null>(null);

type Props = {
    children ?: ReactNode;
};

const FavoritesProvider : React.FC<Props> = ({children} : Props) => {
    const [favorites, dispatch] = useReducer(favoritesReducer, []);

    return (
        <favoritesContext.Provider value={favorites}>
            <favoritesDispatchContext.Provider value={dispatch}>
                {children}
            </favoritesDispatchContext.Provider>
        </favoritesContext.Provider>
    );
};

type FavoritesContext = {
    favorites : Favorites;
    loadFavorites : (routeIds : number[]) => void;
    addFavorite : (routeId : number) => void;
    removeFavorite : (routeId : number) => void;
};

export const useFavorites = () : FavoritesContext => {
    const favorites = useContext(favoritesContext);
    const dispatch = useContext(favoritesDispatchContext);

    if (!dispatch) {
        throw new Error('Context was used outside of provider');
    }

    const loadFavorites = useCallback((routeIds : number[]) => dispatch({
        type: 'LOAD_FAVORITES',
        payload: {routeIds}
    }), [dispatch]);

    const addFavorite = useCallback((routeId : number) => dispatch({
        type: 'ADD_FAVORITE',
        payload: {routeId},
    }), [dispatch]);

    const removeFavorite = useCallback((routeId : number) => dispatch({
        type: 'REMOVE_FAVORITE',
        payload: {routeId},
    }), [dispatch]);

    return useMemo(() => ({
        loadFavorites,
        addFavorite,
        removeFavorite,
        favorites,
    }), [favorites, loadFavorites, addFavorite, removeFavorite]);
};

export default FavoritesProvider;
