import Typography from '@material-ui/core/Typography';
import {makeValidate, TextField} from 'mui-rff';
import {useSnackbar} from 'notistack';
import React, {useCallback} from 'react';
import {Form} from 'react-final-form';
import {useHistory} from 'react-router';
import * as yup from 'yup';
import LoadingButton from '../../components/LoadingButton';
import PaddedContainer from '../../components/PaddedContainer';
import {apiEndpoint} from '../../components/Providers/AuthProvider';

const schema = yup.object({
    emailAddress: yup.string().label('Email address').email().required(),
}).required();

const validate = makeValidate(schema);

type FormData = yup.InferType<typeof schema>;

const Unauthorized : React.FC = () => {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();

    const handleSubmit = useCallback(async (formData : FormData) => {
        const data = schema.cast(formData);
        let response;

        try {
            response = await fetch(
                new URL('/wp-json/flags_on_route/v1/request-volunteer-token', apiEndpoint).toString(),
                {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(data),
                }
            );
        } catch (e) {
            enqueueSnackbar('Could not contact server, are you offline?', {variant: 'error'});
            return;
        }

        if (!response.ok) {
            return {emailAddress: 'Unknown email address'};
        }

        enqueueSnackbar('An email has been sent to your address', {variant: 'success'});
    }, [enqueueSnackbar]);

    let title = 'You are not authenticated';

    switch (history.location.pathname) {
    case '/signed-out':
        title = 'You are now signed out';
        break;

    case '/invalid-link':
        title = "Sorry, your link didn't work";
        break;
    }

    return (
        <PaddedContainer>
            <Typography variant="h6">
                {title}
            </Typography>
            <Typography paragraph>
                Please enter your email address to reset your link. If your email address is on file with us, you'll
                {' '}receive a new link in a few moments. If you have any problems, consult your organization manager.
            </Typography>

            <Form
                subscription={{submitting: true}}
                onSubmit={handleSubmit}
                validate={validate}
                render={({handleSubmit, submitting}) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <TextField
                            name="emailAddress"
                            label="Email address"
                            variant="outlined"
                            disabled={submitting}
                            type="email"
                            required
                        />

                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="secondary"
                            disabled={submitting}
                            loading={submitting}
                        >Get a new link</LoadingButton>
                    </form>
                )}
            />
        </PaddedContainer>
    );
};

export default Unauthorized;
