import {createMuiTheme} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {SnackbarProvider} from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#ac3322',
            contrastText: '#fff',
        },
        secondary: {
            main: '#0b3673',
            contrastText: '#fff',
        },
    },
    props: {
        MuiTextField: {
            margin: 'normal',
        },
        MuiFormControl: {
            margin: 'normal',
        },
    },
});

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
            <>
                <CssBaseline/>
                <App/>
            </>
        </SnackbarProvider>
    </MuiThemeProvider>,
    document.getElementById('root')
);
