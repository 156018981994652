import {CircularProgress} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles(() => ({
    wrapper: {
        display: 'inline-block',
        position: 'relative',
    },
    progress: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        marginLeft: -12,
        marginTop: -12,
    },
}));

type Props = React.ComponentPropsWithoutRef<typeof Button> & {
    loading ?: boolean;
};

const LoadingButton : React.FC<Props> = ({loading, ...rest} : Props) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <Button {...rest}/>
            {loading && (
                <CircularProgress
                    color={rest.color === 'default' ? 'primary' : rest.color}
                    size={24}
                    className={classes.progress}
                />
            )}
        </div>
    );
};

export default LoadingButton;
