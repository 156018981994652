import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import React, {MouseEvent, useState} from 'react';
import {useNotifications} from '../Providers/NotificationsProvider';

const useStyles = makeStyles(theme => ({
    noNotifications: {
        padding: theme.spacing(2),
    },
}));

const NotificationsIndicator : React.FC = () => {
    const classes = useStyles();
    const {notifications, markAsRead} = useNotifications();
    const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);

    const handleOpen = (event : MouseEvent<HTMLButtonElement>) => {
        setAnchorElement(event.currentTarget);
        markAsRead();
    };

    return (
        <>
            <IconButton edge="end" color="inherit" onClick={handleOpen}>
                {!notifications.global && !notifications.route ? (
                    <NotificationsNoneIcon/>
                ) : notifications.global?.read === false || notifications.route?.read === false ? (
                    <NotificationsActiveIcon/>
                ) : (
                    <NotificationsIcon/>
                )}
            </IconButton>

            <Popover
                open={Boolean(anchorElement)}
                anchorEl={anchorElement}
                onClose={() => setAnchorElement(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {!notifications.global && !notifications.route ? (
                    <Typography className={classes.noNotifications}>
                        You have no notifications.
                    </Typography>
                ) : (
                    <List>
                        {notifications.global && (
                            <ListItem>
                                <ListItemIcon><InfoIcon/></ListItemIcon>
                                <ListItemText>{notifications.global.message}</ListItemText>
                            </ListItem>
                        )}
                        {notifications.route && (
                            <ListItem>
                                <ListItemIcon><InfoIcon/></ListItemIcon>
                                <ListItemText>{notifications.route.message}</ListItemText>
                            </ListItem>
                        )}
                    </List>
                )}
            </Popover>
        </>
    );
};

export default NotificationsIndicator;
