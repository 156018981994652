import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, {ReactNode} from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
}));

type Props = {
    children : ReactNode;
};

const PaddedContainer : React.FC<Props> = ({children} : Props) => {
    const classes = useStyles();

    return (
        <Container maxWidth="md" className={classes.root}>
            <>{children}</>
        </Container>
    );
};

export default PaddedContainer;
