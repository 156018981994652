import {Grid} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import {Checkboxes, makeValidate, TextField} from 'mui-rff';
import {useSnackbar} from 'notistack';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Field, Form} from 'react-final-form';
import {StringSchema} from 'yup';
import * as yup from 'yup';
import PaddedContainer from '../PaddedContainer';
import {Address} from '../PreloadRoute';
import {useAuthData} from '../Providers/AuthProvider';
import {AddressUpdate} from '../Route/RouteDialog';
import Photo from './Photo';

const useStyles = makeStyles(theme => ({
    submitButton: {
        marginTop: theme.spacing(2),
    },
}));

const schema = yup.object({
    pickupIssues: yup.string().nullable().default(null),
    flagLeans: yup.boolean().default(false),
    missingSleeve: yup.boolean().default(false),
    missingMarker: yup.boolean().default(false),
    sleeveTooDeep: yup.boolean().default(false),
    photoProblem: yup.boolean().default(false),
    other: yup.boolean().default(false),
    otherDescription: yup.string().label('Explanation').when(
        'other',
        (checked : boolean, schema : StringSchema) => checked ? schema.required() : schema.nullable().default(null)
    ),
}).required();

const validate = makeValidate(schema);

type Props = {
    open : boolean;
    onClose : () => void;
    address : Address;
    onUpdate : (addressId : number, update : AddressUpdate) => void;
};

const ReportIssueDialog : React.FC<Props> = ({open, onClose, address, onUpdate} : Props) => {
    const classes = useStyles();
    const {mode} = useAuthData();
    const [issueImage, setIssueImage] = useState<string | null>(null);
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => setIssueImage(null), [address.installIssues.imageUrl]);

    const initialValues = useMemo(() => ({
        ...address.installIssues,
        image: undefined,
        other: address.installIssues.other !== null,
        otherDescription: address.installIssues.other,
        pickupIssues: address.pickupIssues,
    }), [address]);

    type FormData = yup.InferType<typeof schema>;

    const handleSubmit = useCallback((values : FormData) => {
        const data = schema.cast(values);

        onUpdate(address.id, {
            pickupIssues: mode === 'pickup' ? data.pickupIssues : undefined,
            installIssues: {
                flagLeans: data.flagLeans,
                missingSleeve: data.missingSleeve,
                missingMarker: data.missingMarker,
                sleeveTooDeep: data.sleeveTooDeep,
                photoProblem: data.photoProblem,
                other: data.other ? data.otherDescription : null,
                image: issueImage || undefined,
            },
        });

        enqueueSnackbar('Issue has been submitted', {variant: 'success'});
        onClose();
    }, [onUpdate, address.id, mode, issueImage, onClose, enqueueSnackbar]);

    return (
        <Dialog fullScreen open={open}>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6">
                        Report Issue
                    </Typography>
                </Toolbar>
            </AppBar>
            <PaddedContainer>
                <Form
                    validate={validate}
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    render={({handleSubmit}) => (
                        <form onSubmit={handleSubmit} noValidate>
                            {mode === 'pickup' && (
                                <TextField
                                    name="pickupIssues"
                                    label="Pickup issues"
                                    helperText="If you have any problem with pickup, please explain"
                                    variant="outlined"
                                    rows={5}
                                    multiline
                                />
                            )}

                            <Grid container direction="column">
                                <Grid item>
                                    <Checkboxes
                                        name="flagLeans"
                                        data={{label: 'Flag leans', value: true}}
                                        formControlProps={{margin: 'none'}}
                                    />
                                </Grid>
                                <Grid item>
                                    <Checkboxes
                                        name="missingSleeve"
                                        data={{label: 'Missing sleeve', value: true}}
                                        formControlProps={{margin: 'none'}}
                                    />
                                </Grid>
                                <Grid item>
                                    <Checkboxes
                                        name="missingMarker"
                                        data={{label: 'Missing marker', value: true}}
                                        formControlProps={{margin: 'none'}}
                                    />
                                </Grid>
                                <Grid item>
                                    <Checkboxes
                                        name="sleeveTooDeep"
                                        data={{label: 'Sleeve too deep', value: true}}
                                        formControlProps={{margin: 'none'}}
                                    />
                                </Grid>
                                <Grid item>
                                    <Checkboxes
                                        name="photoProblem"
                                        data={{label: 'Photo problem', value: true}}
                                        formControlProps={{margin: 'none'}}
                                    />
                                </Grid>
                                <Grid item>
                                    <Checkboxes
                                        name="other"
                                        data={{label: 'Other', value: true}}
                                        formControlProps={{margin: 'none'}}
                                    />
                                </Grid>
                            </Grid>

                            <Field name="other" subscription={{value: true}} render={({input: {value}}) => (
                                <>
                                    {value && (
                                        <TextField
                                            name="otherDescription"
                                            label="Other"
                                            helperText="Please explain your problem"
                                            variant="outlined"
                                            rows={5}
                                            multiline
                                            required
                                        />
                                    )}
                                </>
                            )}/>

                            <Photo
                                title="Issue illustration"
                                imageUrl={
                                    issueImage ? 'data:image/jpeg;base64,' + issueImage : address.installIssues.imageUrl
                                }
                                onUpdate={setIssueImage}
                            />

                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                className={classes.submitButton}
                            >
                                Submit issue
                            </Button>
                        </form>
                    )}
                />
            </PaddedContainer>
        </Dialog>
    );
};

export default ReportIssueDialog;
