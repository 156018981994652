import LinearProgress from '@material-ui/core/LinearProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import {useAddressUpdate} from './Providers/AddressUpdateProvider';

const useStyles = makeStyles(theme => ({
    indicator: {
        position: 'fixed',
        top: 64,
        width: '100%',
        zIndex: 10000,
        [theme.breakpoints.down('xs')]: {
            top: 56,
        },
    },
}));

const UpdateIndicator : React.FC = () => {
    const classes = useStyles();
    const {running} = useAddressUpdate();

    if (!running) {
        return null;
    }

    return (
        <div className={classes.indicator}>
            <LinearProgress
                variant="indeterminate"
                color="secondary"
            />
        </div>
    );
};

export default UpdateIndicator;
