import React, {
    createContext,
    Dispatch,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer
} from 'react';
import {apiEndpoint, useApiFetch} from './AuthProvider';

export type Notification = {
    message : string;
    read : boolean;
};

export type NotificationKey = 'global' | 'route';

export type Notifications = {
    global : Notification | null;
    route : Notification | null;
    routeId : number | null;
}

type LoadMessagesAction = {
    type : 'LOAD_MESSAGES';
    payload : {
        global ?: string;
        route ?: string;
    };
};

type MarkAsReadAction = {
    type : 'MARK_AS_READ';
    payload : {
        notificationKey ?: NotificationKey;
    };
};

type EnterRouteContextAction = {
    type : 'ENTER_ROUTE_CONTEXT';
    payload : {
        routeId : number;
    };
};

type ExitRouteContextAction = {
    type : 'EXIT_ROUTE_CONTEXT';
};

type Action = LoadMessagesAction | MarkAsReadAction | EnterRouteContextAction | ExitRouteContextAction;

const notificationsReducer = (state : Notifications, action : Action) : Notifications => {
    switch (action.type) {
    case 'LOAD_MESSAGES': {
        if ((
            (!state.global && !action.payload.global)
                || (state.global && state.global.message === action.payload.global)
        ) && (
            (!state.route && !action.payload.route)
                || (state.route && state.route.message === action.payload.route)
        )) {
            // No changes
            return state;
        }

        const newState : Notifications = {...state};

        if (action.payload.global) {
            newState.global = state.global && state.global.message === action.payload.global
                ? state.global
                : {message: action.payload.global, read: false};
        } else {
            newState.global = null;
        }

        if (action.payload.route) {
            newState.route = state.route && state.route.message === action.payload.route
                ? state.route
                : {message: action.payload.route, read: false};
        } else {
            newState.route = null;
        }

        return newState;
    }

    case 'MARK_AS_READ':
        return {
            ...state,
            global: state.global ? {
                ...state.global,
                read: state.global.read
                            || !action.payload.notificationKey
                            || action.payload.notificationKey === 'global'
            } : null,
            route: state.route ? {
                ...state.route,
                read: state.route.read
                            || !action.payload.notificationKey
                            || action.payload.notificationKey === 'route'
            } : null
        };

    case 'ENTER_ROUTE_CONTEXT':
        return {
            ...state,
            routeId: action.payload.routeId,
        };

    case 'EXIT_ROUTE_CONTEXT':
        return {
            ...state,
            route: null,
            routeId: null,
        };
    }
};

const notificationsContext = createContext<Notifications>({global: null, route: null, routeId: null});
const notificationsDispatchContext = createContext<Dispatch<Action> | null>(null);

type Props = {
    children ?: ReactNode;
};

const NotificationsProvider : React.FC<Props> = ({children} : Props) => {
    const apiFetch = useApiFetch();
    const [notifications, dispatch] = useReducer(notificationsReducer, {global: null, route: null, routeId: null});

    const checkNotifications = useCallback(async (routeId : number | null, signal : AbortSignal) => {
        let response;
        let path = '/wp-json/flags_on_route/v1/message';

        if (routeId) {
            path += `/${routeId}`;
        }

        try {
            response = await apiFetch(new URL(path, apiEndpoint).toString(), {signal});
        } catch (e) {
            return;
        }

        if (!response.ok) {
            return;
        }

        const {data} = await response.json();

        if (signal.aborted) {
            return;
        }

        dispatch({
            type: 'LOAD_MESSAGES',
            payload: {
                global: data.globalMessage,
                route: data.routeMessage,
            },
        });
    }, [apiFetch, dispatch]);

    useEffect(() => {
        const controller = new AbortController();
        checkNotifications(notifications.routeId, controller.signal);

        const interval = window.setInterval(() => {
            checkNotifications(notifications.routeId, controller.signal);
        }, 60 * 1000);

        return () => {
            controller.abort();
            window.clearInterval(interval);
        };
    }, [checkNotifications, notifications.routeId]);

    return (
        <notificationsContext.Provider value={notifications}>
            <notificationsDispatchContext.Provider value={dispatch}>
                {children}
            </notificationsDispatchContext.Provider>
        </notificationsContext.Provider>
    );
};

type NotificationsContext = {
    notifications : Notifications;
    markAsRead : (notificationsKey ?: NotificationKey) => void;
    enterRouteContext : (routeId : number) => void;
    exitRouteContext : () => void;
};

export const useNotifications = () : NotificationsContext => {
    const notifications = useContext(notificationsContext);
    const dispatch = useContext(notificationsDispatchContext);

    if (!dispatch) {
        throw new Error('Context was used outside of provider');
    }

    const markAsRead = useCallback((notificationKey ?: NotificationKey) => dispatch({
        type: 'MARK_AS_READ',
        payload: {
            notificationKey,
        },
    }), [dispatch]);

    const enterRouteContext = useCallback((routeId : number) => dispatch({
        type: 'ENTER_ROUTE_CONTEXT',
        payload: {routeId},
    }), [dispatch]);

    const exitRouteContext = useCallback(() => dispatch({
        type: 'EXIT_ROUTE_CONTEXT',
    }), [dispatch]);

    return useMemo(() => ({
        notifications,
        markAsRead,
        enterRouteContext,
        exitRouteContext,
    }), [notifications, markAsRead, enterRouteContext, exitRouteContext]);
};

export default NotificationsProvider;
