import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

type Props = {
    open : boolean;
};

const LoadingBackdrop : React.FC<Props> = ({open} : Props) => {
    const classes = useStyles();

    return (
        <Backdrop open={open} className={classes.backdrop}>
            <CircularProgress color="secondary"/>
        </Backdrop>
    );
};

export default LoadingBackdrop;
