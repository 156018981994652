import Button from '@material-ui/core/Button';
import {SnackbarKey, useSnackbar} from 'notistack';
import React, {useCallback, useEffect, useRef} from 'react';
import {NotificationKey, Notifications, useNotifications} from '../Providers/NotificationsProvider';

type CurrentNotification = {
    message : string;
    snackbarKey : SnackbarKey;
};

type CurrentNotifications = {
    global : CurrentNotification | null;
    route : CurrentNotification | null;
};

const disableNotifications = process.env.REACT_APP_DISABLE_NOTIFICATIONS === 'true';

const NotificationsSnackbar : React.FC = () => {
    const {notifications, markAsRead} = useNotifications();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const currentNotifications = useRef<CurrentNotifications>({global: null, route: null});

    const handleNotificationChange = useCallback((key : NotificationKey, notifications : Notifications) => {
        if (disableNotifications) {
            return;
        }

        const notification = notifications[key];
        const currentNotification = currentNotifications.current[key];

        if (notification
            && !notification.read
            && (!currentNotification || currentNotification.message !== notification.message)
        ) {
            if (currentNotification) {
                closeSnackbar(currentNotification.snackbarKey);
            }

            currentNotifications.current[key] = {
                message: notification.message,
                snackbarKey: enqueueSnackbar(notification.message, {
                    variant: 'info',
                    persist: true,
                    preventDuplicate: true,
                    action: (
                        <Button onClick={() => markAsRead(key)} color="inherit">
                            Dismiss
                        </Button>
                    ),
                }),
            };
        } else if (currentNotification && (!notification || notification.read)) {
            closeSnackbar(currentNotification.snackbarKey);
            currentNotifications.current[key] = null;
        }
    }, [currentNotifications, enqueueSnackbar, closeSnackbar, markAsRead]);

    useEffect(() => {
        handleNotificationChange('global', notifications);
        handleNotificationChange('route', notifications);
    }, [notifications, enqueueSnackbar, handleNotificationChange]);

    return null;
};

export default NotificationsSnackbar;
