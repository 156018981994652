import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles} from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import TodoIcon from '@material-ui/icons/EmojiFlags';
import React, {useCallback, useState} from 'react';
import {Route} from '../../pages/ListRoutes';
import {usePreloadRoute} from '../PreloadRoute';
import {useNotifications} from '../Providers/NotificationsProvider';
import FavoriteButton from './FavoriteButton';
import RouteDialog from './RouteDialog';

const useStyles = makeStyles(theme => ({
    done: {
        color: theme.palette.success.main,
    },
}));

type Props = {
    route : Route;
    onUnfavorite : () => void;
    onClose : (routeId : number, addressesDone ?: number) => void;
};

const RouteListItem : React.FC<Props> = ({route, onUnfavorite, onClose} : Props) => {
    const classes = useStyles();
    const preloadRoute = usePreloadRoute();
    const {enterRouteContext, exitRouteContext} = useNotifications();
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleFavoriteChange = useCallback((routeId : number, isFavorite : boolean) => {
        if (!isFavorite) {
            onUnfavorite();
            return;
        }

        preloadRoute(routeId);
    }, [onUnfavorite, preloadRoute]);

    const handleOpen = () => {
        setDialogOpen(true);
        enterRouteContext(route.id);
    };

    const handleClose = useCallback((addressesDone ?: number) => {
        setDialogOpen(false);
        onClose(route.id, addressesDone);
        exitRouteContext();
    }, [setDialogOpen, exitRouteContext, route, onClose]);

    return (
        <>
            <ListItem button onClick={handleOpen}>
                <ListItemIcon>
                    {route.addressesDone < route.totalAddresses ? (
                        <TodoIcon/>
                    ) : (
                        <DoneIcon className={classes.done}/>
                    )}
                </ListItemIcon>
                <ListItemText
                    primary={route.name}
                    secondary={[
                        `${route.addressesDone} of ${route.totalAddresses} addresses done`,
                        `${route.totalFlags} flag${route.totalFlags !== 1 ? 's' : ''}`
                    ].join(' / ')}
                />
                <ListItemSecondaryAction>
                    <FavoriteButton routeId={route.id} edge="end" onChange={handleFavoriteChange}/>
                </ListItemSecondaryAction>
            </ListItem>

            <RouteDialog
                routeId={route.id}
                open={dialogOpen}
                routeName={route.name}
                onClose={handleClose}
            />
        </>
    );
};

export default RouteListItem;
