import {useSnackbar} from 'notistack';
import React, {ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useHistory} from 'react-router';
import FullPageLoadingIndicator from '../FullPageLoadingIndicator';

export type Mode = 'installation' | 'pickup';

export type AuthData = {
    token : string;
    expiresAt : number;
    user : {
        id : number;
        displayName : string;
        emailAddress : string;
        termsAccepted : boolean;
    };
    mode : Mode;
    organization : string;
    texts : {
        help : string;
    },
};

export type AuthState = {
    isAuthenticated : true;
    data : AuthData;
} | {
    isAuthenticated : false;
};

type AuthActions = {
    markTermsAccepted : () => void;
    clearAuth : () => void;
    signOut : () => void;
};

const authContext = React.createContext<AuthState | null>(null);
const authActionsContext = React.createContext<AuthActions | null>(null);

type Props = {
    children ?: ReactElement;
};

const AuthProvider : React.FC<Props> = ({children} : Props) => {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [authData, setAuthData] = useState<AuthData | null>(null);
    const [loading, setLoading] = useState(true);
    const authenticating = useRef(false);

    const getStoredAuthData = useCallback(() => {
        const rawAuthData = localStorage.getItem('authData');

        if (!rawAuthData) {
            return null;
        }

        const authData : AuthData = JSON.parse(rawAuthData);

        if (authData.expiresAt < (Date.now() / 1000)) {
            return null;
        }

        return authData;
    }, []);

    const authenticate = useCallback(async (volunteerToken : string, previousAuthData : AuthData | null) => {
        const response = await fetch(new URL('/wp-json/flags_on_route/v1/authenticate', apiEndpoint).toString(), {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({volunteerToken}),
        });

        if (!response.ok) {
            if (!previousAuthData) {
                return null;
            }

            return previousAuthData;
        }

        const {data} = await response.json();
        const authData : AuthData = {
            token: data.token,
            expiresAt: Math.floor(Date.now() / 1000) + data.expire - 60,
            user: {
                id: data.id,
                displayName: data.name,
                emailAddress: data.emailAddress,
                termsAccepted: data.tou,
            },
            organization: data.organizationName,
            mode: data.mode,
            texts: {
                help: data.helpText,
            },
        };

        return authData;
    }, []);

    useEffect(() => {
        if (authenticating.current) {
            return;
        }

        authenticating.current = true;

        const previousAuthData = getStoredAuthData();
        const search = new URLSearchParams(history.location.search);
        const volunteerToken = search.get('volunteerToken');

        if (!volunteerToken) {
            setAuthData(previousAuthData);
            setLoading(false);
            return;
        }

        (async () => {
            const newAuthData = await authenticate(volunteerToken, previousAuthData);
            setLoading(false);

            if (!newAuthData) {
                setAuthData(null);
                localStorage.removeItem('authData');
                history.replace('/invalid-link');
                authenticating.current = false;
                return;
            }

            setAuthData(newAuthData);
            localStorage.setItem('authData', JSON.stringify(newAuthData));
            enqueueSnackbar(`Signed in as ${newAuthData.user.displayName}`, {variant: 'success'});
            history.replace(history.location.pathname);
            authenticating.current = false;
        })();
    }, [history, getStoredAuthData, authenticate, setAuthData, enqueueSnackbar]);

    const markTermsAccepted = useCallback(() => {
        if (!authData) {
            return;
        }

        const newAuthData = {
            ...authData,
            user: {
                ...authData.user,
                termsAccepted: true,
            },
        };

        setAuthData(newAuthData);
        localStorage.setItem('authData', JSON.stringify(newAuthData));
    }, [authData, setAuthData]);

    const clearAuth = useCallback(() => {
        localStorage.removeItem('authData');
        setAuthData(null);
    }, [setAuthData]);

    const signOut = useCallback(() => {
        clearAuth();
        history.push('/signed-out');
    }, [history, clearAuth]);

    const authActions = useMemo(() => ({
        markTermsAccepted,
        clearAuth,
        signOut
    }), [markTermsAccepted, clearAuth, signOut]);

    if (loading) {
        return <FullPageLoadingIndicator/>;
    }

    const value : AuthState = authData !== null ? {
        isAuthenticated: true,
        data: authData,
    } : {isAuthenticated: false};

    return (
        <authContext.Provider value={value}>
            <authActionsContext.Provider value={authActions}>
                {children}
            </authActionsContext.Provider>
        </authContext.Provider>
    );
};

export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const useAuth = () : AuthState => {
    const auth = useContext(authContext);

    if (!auth) {
        throw new Error('Context was used before initial load');
    }

    return auth;
};

export const useAuthData = () : AuthData => {
    const auth = useAuth();

    if (!auth.isAuthenticated) {
        throw new Error('Context was used before authentication');
    }

    return auth.data;
};

export const useAuthActions = () : AuthActions => {
    const authActions = useContext(authActionsContext);

    if (!authActions) {
        throw new Error('Context was used before initial load');
    }

    return authActions;
};

type ApiFetch = (url : string, init ?: RequestInit) => Promise<Response>;

export const useApiFetch = () : ApiFetch => {
    const authData = useAuthData();
    const {clearAuth} = useAuthActions();

    return useCallback(async (url : string, init ?: RequestInit) : Promise<Response> => {
        if (!init) {
            init = {};
        }

        init.headers = init.headers instanceof Headers ? init.headers : new Headers(init.headers);
        init.headers.append('Authorization', `Bearer ${authData.token}`);
        init.headers.append('Content-Type', 'application/json');

        const response = await fetch(url, init);

        if (response.status === 403) {
            clearAuth();
        }

        return response;
    }, [authData, clearAuth]);
};

export default AuthProvider;
