import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const FullPageLoadingIndicator : React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}><CircularProgress size={80}/></div>
    );
};

export default FullPageLoadingIndicator;
