import {useCallback} from 'react';
import {apiEndpoint, useApiFetch} from './Providers/AuthProvider';

export type InstallIssues = {
    flagLeans : boolean;
    missingSleeve : boolean;
    missingMarker : boolean;
    sleeveTooDeep : boolean;
    photoProblem : boolean;
    other : string;
    imageUrl : string | null;
};

export type Flag = {
    id : string;
    imageUrl : string | null;
};

export type Address = {
    id : number;
    streetAddress : string;
    city : string;
    state : string;
    zipCode : string;
    flags : Flag[];
    installIssues : InstallIssues;
    pickupIssues : string | null;
    addressNotes : string | null;
    pickedUp : boolean;
};

export type Route = {
    id : number;
    name : string;
    mapUrl : string | null;
    installationCompletionMessage : string;
    pickupCompletionMessage : string;
    addresses : Address[];
};

type MarshalRouteInput = {
    id : number;
    routeName : string;
    routeMap : string | null;
    completionText : string;
    completionTextPickup : string;
    addresses : Array<Omit<Address, 'flags' | 'installIssues'> & {
        flags : Array<{id : string; image : string | null}>;
        installIssues : Omit<InstallIssues, 'imageUrl'> & {image : string | null};
    }>;
};

export const marshalRoute = (data : MarshalRouteInput) : Route => ({
    id : data.id,
    name: data.routeName,
    mapUrl: data.routeMap,
    installationCompletionMessage: data.completionText,
    pickupCompletionMessage: data.completionTextPickup,
    addresses: data.addresses.map(address => ({
        ...address,
        flags: address.flags.map(flag => ({
            id: flag.id,
            imageUrl: flag.image,
        })),
        installIssues: {
            ...address.installIssues,
            imageUrl: address.installIssues.image,
        },
    })),
});

type PreloadRoute = (routeId : number, route ?: Route) => Promise<void>;

export const usePreloadRoute = () : PreloadRoute => {
    const apiFetch = useApiFetch();

    return useCallback(async (routeId : number, route ?: Route) => {
        let response;

        if (!route) {
            try {
                response = await apiFetch(new URL(`/wp-json/flags_on_route/v1/routes/${routeId}`, apiEndpoint).toString());
            } catch (e) {
                return;
            }

            if (!response.ok) {
                return;
            }

            const {data} = await response.json();
            route = marshalRoute(data.route);
        }

        const imageUrls : string[] = [];

        if (route.mapUrl) {
            imageUrls.push(route.mapUrl);
        }

        for (const address of route.addresses) {
            imageUrls.push(...address.flags.map(flag => flag.imageUrl).filter(Boolean) as string[]);

            if (address.installIssues.imageUrl) {
                imageUrls.push(address.installIssues.imageUrl);
            }
        }

        await Promise.all(imageUrls.map(imageUrl => new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener('load', resolve);
            image.addEventListener('error', reject);
            image.src = imageUrl;
        })));
    }, [apiFetch]);
};
