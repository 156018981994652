import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        padding: theme.spacing(2, 0),
    },
}));

type Props = {
    className ?: string;
};

const InlineLoadingIndicator : React.FC<Props> = ({className} : Props) => {
    const classes = useStyles();

    return (
        <div className={classNames(classes.root, className)}><CircularProgress/></div>
    );
};

export default InlineLoadingIndicator;
