import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import React, {ReactNode, useState} from 'react';
import NavigationDrawer from './NavigationDrawer';
import NotificationsIndicator from './Notifications/NotificationsIndicator';
import {useAuthData} from './Providers/AuthProvider';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    drawer: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
}));

type Props = {
    action ?: ReactNode;
};

const UserAppBar : React.FC<Props> = ({action} : Props) => {
    const classes = useStyles();
    const authData = useAuthData();
    const [drawerOpen, setDrawerOpen] = useState(false);

    return (
        <>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => setDrawerOpen(!drawerOpen)}>
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {authData.organization}
                    </Typography>
                    {action}
                    <NotificationsIndicator/>
                </Toolbar>
            </AppBar>

            <Drawer
                variant="temporary"
                open={drawerOpen}
                ModalProps={{keepMounted: true}}
                className={classes.drawerPaper}
                onClose={() => setDrawerOpen(false)}
            >
                <nav className={classes.drawer}>
                    <NavigationDrawer onSelect={() => setDrawerOpen(false)}/>
                </nav>
            </Drawer>
        </>
    );
};

export default UserAppBar;
