import Typography from '@material-ui/core/Typography';
import {useSnackbar} from 'notistack';
import React, {useCallback, useEffect, useState} from 'react';
import InlineLoadingIndicator from '../../components/InlineLoadingIndicator';
import {useLoadError} from '../../components/LoadError';
import LoadingButton from '../../components/LoadingButton';
import PaddedContainer from '../../components/PaddedContainer';
import Paragraphs from '../../components/Paragraphs';
import {apiEndpoint, useApiFetch, useAuthActions, useAuthData} from '../../components/Providers/AuthProvider';

const AcceptTerms : React.FC = () => {
    const authData = useAuthData();
    const loadError = useLoadError();
    const {enqueueSnackbar} = useSnackbar();
    const apiFetch = useApiFetch();
    const {markTermsAccepted} = useAuthActions();
    const [terms, setTerms] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const loadTerms = useCallback(async () => {
        let response;

        try {
            response = await apiFetch(new URL('/wp-json/flags_on_route/v1/read-tou', apiEndpoint).toString());

            if (response.status === 403) {
                return;
            }
        } catch (e) {
            response = null;
        }

        if (!response || !response.ok) {
            loadError('terms', loadTerms);
            return;
        }

        const {data} = await response.json();
        setTerms(data.touText);
    }, [apiFetch, loadError, setTerms]);

    useEffect(() => {
        loadTerms();
    }, [loadTerms]);

    const acceptTerms = async () => {
        setLoading(true);
        let response;

        try {
            response = await apiFetch(new URL('/wp-json/flags_on_route/v1/tou', apiEndpoint).toString(), {
                method: 'POST',
                body: JSON.stringify({accept: true}),
            });
        } catch (e) {
            return enqueueSnackbar('Could not contact server', {variant: 'error'});
        } finally {
            setLoading(false);
        }

        if (!response.ok) {
            enqueueSnackbar('An unknown error occurred', {variant: 'error'});
            return;
        }

        markTermsAccepted();
    };

    return (
        <PaddedContainer>
            <Typography paragraph>
                Thank you for installing flags for {authData.organization}.
            </Typography>
            <Typography paragraph>
                To get started, please accept the following terms of service below, protecting the safety and privacy of
                {' '} you and our subscribers.
            </Typography>

            {terms === null ? (
                <InlineLoadingIndicator/>
            ) : (
                <>
                    <Paragraphs text={terms}/>

                    <LoadingButton
                        variant="contained"
                        onClick={acceptTerms}
                        color="secondary"
                        disabled={loading}
                        loading={loading}
                    >
                        I agree
                    </LoadingButton>
                </>
            )}
        </PaddedContainer>
    );
};

export default AcceptTerms;
