import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import MapIcon from '@material-ui/icons/Map';
import React, {ChangeEvent, useState} from 'react';
import MapDialog from '../Map/MapDialog';
import {Address} from '../PreloadRoute';
import {useAuthData} from '../Providers/AuthProvider';
import AddressStatusIcon from '../Route/AddressStatusIcon';
import {AddressUpdate} from '../Route/RouteDialog';
import Photo from './Photo';

const useStyles = makeStyles(theme => ({
    card: {
        marginBottom: theme.spacing(2),
    },
    photo: {
        marginBottom: theme.spacing(2),
    },
    bottomNavigation: {
        width: '100%',
        position: 'fixed',
        top: 'auto',
        bottom: 0,
    },
}));

type Props = {
    address : Address;
    fallbackRouteMapUrl : string | null;
    onUpdate : (addressId : number, update : AddressUpdate) => void;
    onOpenIssue : () => void;
};

const AddressDetails : React.FC<Props> = ({address, fallbackRouteMapUrl, onUpdate, onOpenIssue} : Props) => {
    const classes = useStyles();
    const authData = useAuthData();
    const [mapDialogOpen, setMapDialogOpen] = useState(false);

    const handlePickedUpChanged = (event : ChangeEvent<HTMLInputElement>) => {
        onUpdate(address.id, {pickedUp: event.target.checked});
    };

    const handlePhotoUpdate = (id : string, image : string) => {
        onUpdate(address.id, {flags: {[id]: image}});
    };

    return (
        <>
            <Card className={classes.card}>
                <CardHeader
                    avatar={<AddressStatusIcon address={address} size="large" onIssueClick={onOpenIssue}/>}
                    title={address.streetAddress}
                    subheader={`${address.flags.length} flag${address.flags.length !== 1 ? 's' : ''}`}
                />

                {(authData.mode === 'pickup' || address.addressNotes) && (
                    <CardContent>
                        {address.addressNotes && (
                            <Typography variant="body2">
                                {address.addressNotes}
                            </Typography>
                        )}

                        {authData.mode === 'pickup' && (
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        checked={address.pickedUp}
                                        onChange={handlePickedUpChanged}
                                        color="secondary"
                                    />
                                )}
                                label={`
                                    ${address.flags.length} flag${address.flags.length !== 1 ? 's': ''} picked up
                                `}
                            />
                        )}
                    </CardContent>
                )}

                <CardActions>
                    <Button
                        startIcon={<MapIcon/>}
                        size="small"
                        onClick={() => setMapDialogOpen(true)}
                    >
                        Map It
                    </Button>

                    <MapDialog
                        open={mapDialogOpen}
                        onClose={() => setMapDialogOpen(false)}
                        addresses={[address]}
                        fallbackRouteMapUrl={fallbackRouteMapUrl}
                    />
                </CardActions>
            </Card>

            {address.flags.map(flag => (
                <Photo
                    key={flag.id}
                    title={flag.id}
                    className={classes.photo}
                    imageUrl={flag.imageUrl}
                    onUpdate={image => handlePhotoUpdate(flag.id, image)}
                />
            ))}
        </>
    );
};

export default AddressDetails;
