import Typography from '@material-ui/core/Typography';
import React from 'react';

type Props = {
    text : string;
};

const Paragraphs : React.FC<Props> = ({text} : Props) => (
    <>
        {text.trim().split(/\r?\n(?:[\t ]*\r?\n)+/).map((paragraph, index) => {
            let firstLine = true;

            return (
                <Typography paragraph key={index}>
                    {paragraph.split(/\r?\n/).map((line, index) => {
                        if (firstLine) {
                            firstLine = false;
                            return line;
                        }

                        return <React.Fragment key={index}><br/>{line}</React.Fragment>;
                    })}
                </Typography>
            );
        })}
    </>
);

export default Paragraphs;
