import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import MapIcon from '@material-ui/icons/Map';
import {useSnackbar} from 'notistack';
import React, {useCallback, useEffect, useState} from 'react';
import AddressDialog from '../Address/AddressDialog';
import InlineLoadingIndicator from '../InlineLoadingIndicator';
import MapDialog from '../Map/MapDialog';
import {Address, Route} from '../PreloadRoute';
import {useAuthData} from '../Providers/AuthProvider';
import AddressListItem from './AddressListItem';
import {addressStatus} from './AddressStatusIcon';
import {AddressUpdate} from './RouteDialog';

const useStyles = makeStyles(theme => ({
    container: {
        paddingBottom: theme.spacing(8),
    },
    progressLabel: {
        whiteSpace: 'nowrap',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fabIcon: {
        marginRight: theme.spacing(1),
    },
}));

type Props = {
    route : Route | null;
    onClose : () => void;
    onUpdate : (addressId : number, update : AddressUpdate) => void;
    onRefresh : () => Promise<void>;
};

const AddressList : React.FC<Props> = ({route, onClose, onUpdate, onRefresh} : Props) => {
    const classes = useStyles();
    const authData = useAuthData();
    const {enqueueSnackbar} = useSnackbar();
    const [addressDialogOpen, setAddressDialogOpen] = useState(false);
    const [currentAddress, setCurrentAddress] = useState<Address | null>(null)
    const [mapDialogOpen, setMapDialogOpen] = useState(false);
    const [routeCompleteDialogOpen, setRouteCompleteDialogOpen] = useState(false);

    useEffect(() => {
        if (!route || !currentAddress) {
            return;
        }

        const currentRealAddress = route.addresses.find(address => address.id === currentAddress.id);

        if (currentRealAddress && currentRealAddress !== currentAddress) {
            setCurrentAddress(currentRealAddress);
        }
    }, [route, currentAddress, setCurrentAddress]);

    const handleNavigatePrevious = useCallback(() => {
        if (!route || !currentAddress) {
            return;
        }

        const index = route.addresses.indexOf(currentAddress);

        if (index <= 0) {
            return;
        }

        setCurrentAddress(route.addresses[index - 1]);
    }, [route, currentAddress]);

    const handleNavigateNext = useCallback(() => {
        if (!route || !currentAddress) {
            return;
        }

        const index = route.addresses.indexOf(currentAddress);

        if (index < 0) {
            return;
        }

        if ((index + 1) >= route.addresses.length) {
            setAddressDialogOpen(false);

            if (route.addresses.some(address => addressStatus(address, authData.mode) === 'todo')) {
                enqueueSnackbar('This was the last address', {variant: 'info'});
            } else {
                setRouteCompleteDialogOpen(true);
            }
            return;
        }

        setCurrentAddress(route.addresses[index + 1]);
    }, [route, currentAddress, setCurrentAddress, setAddressDialogOpen, authData.mode, enqueueSnackbar]);

    if (!route) {
        return <InlineLoadingIndicator/>;
    }

    const totalAddresses = route.addresses.length;
    const completedAddresses = route.addresses.filter(
        address => addressStatus(address, authData.mode) !== 'todo'
    ).length;
    const progress = totalAddresses > 0 ? completedAddresses / totalAddresses * 100 : 100;

    return (
        <Container
            disableGutters
            maxWidth="md"
            className={classes.container}
        >
            <Box display="flex" alignItems="center" m={2}>
                <Box width="100%" mr={1}>
                    <LinearProgress variant="determinate" value={progress} color="secondary"/>
                </Box>
                <Box>
                    <Typography variant="body2" color="textSecondary" className={classes.progressLabel}>
                        {completedAddresses} of {totalAddresses} done
                    </Typography>
                </Box>
            </Box>
            <Divider/>

            <List>
                {route.addresses.map(address => (
                    <AddressListItem
                        key={address.id}
                        address={address}
                        onClick={() => {
                            setCurrentAddress(address);
                            setAddressDialogOpen(true);
                        }}
                    />
                ))}
            </List>

            {currentAddress && (
                <AddressDialog
                    open={addressDialogOpen}
                    onClose={() => setAddressDialogOpen(false)}
                    address={currentAddress}
                    fallbackRouteMapUrl={route.mapUrl}
                    onNavigatePrevious={handleNavigatePrevious}
                    onNavigateNext={handleNavigateNext}
                    onUpdate={onUpdate}
                    onRefresh={onRefresh}
                />
            )}

            {route.addresses.length > 0 && (
                <>
                    <Fab
                        color="secondary"
                        variant="extended"
                        className={classes.fab}
                        onClick={() => setMapDialogOpen(true)}
                    >
                        <MapIcon className={classes.fabIcon}/>
                        Route Map
                    </Fab>

                    <MapDialog
                        open={mapDialogOpen}
                        onClose={() => setMapDialogOpen(false)}
                        addresses={route.addresses}
                        fallbackRouteMapUrl={route.mapUrl}
                    />
                </>
            )}

            <Dialog
                fullWidth
                maxWidth="sm"
                open={routeCompleteDialogOpen}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle>Route finished</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Congratulations {authData.user.displayName}, your route is finished!
                    </DialogContentText>
                    <DialogContentText>
                        {authData.mode === 'installation'
                            ? route.installationCompletionMessage
                            : route.pickupCompletionMessage
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setRouteCompleteDialogOpen(false);
                            onClose();
                        }}
                        color="secondary"
                    >
                        Return to overview
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default AddressList;
