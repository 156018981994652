import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SwitchIcon from '@material-ui/icons/SyncAlt';
import {DirectionsRenderer, GoogleMap, LoadScript, Marker} from '@react-google-maps/api';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PinchZoomPan from 'react-responsive-pinch-zoom-pan';
import {Address} from '../PreloadRoute';
import DeviceLocation from './DeviceLocation';

const useStyles = makeStyles(theme => ({
    title: {
        flexGrow: 1,
    },
    container: {
        flexGrow: 1,
        height: 'calc(100% - 64px)',
        [theme.breakpoints.down('xs')]: {
            height: 'calc(100% - 56px)',
        },
    },
    mapContainer: {
        width: '100%',
        height: '100%',
    },
    mapImage: {
        display: 'block',
    },
}));

type Props = {
    open : boolean;
    onClose : () => void;
    addresses : Address[];
    fallbackRouteMapUrl : string | null;
};

const formatAddress = (address : Address) => [
    address.streetAddress,
    ', ',
    address.city,
    ', ',
    address.state,
    ' ',
    address.zipCode,
].join('');

const initialCenter = {lat: 37.090240, lng: -95.712891};

const MapDialog : React.FC<Props> = ({open, onClose, addresses, fallbackRouteMapUrl} : Props) => {
    const classes = useStyles();
    const [loadError, setLoadError] = useState(false);
    const [directionsResult, setDirectionsResult] = useState<google.maps.DirectionsResult | null>(null);
    const [destinationLocation, setDestinationLocation] = useState<google.maps.LatLng | null>(null);
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [forceFallback, setForceFallback] = useState(false);

    useEffect(() => {
        if (!open) {
            setLoadError(false);
        }
    }, [open, setLoadError]);

    useEffect(() => {
        setDirectionsResult(null);
        setDestinationLocation(null);
    }, [addresses]);

    const [waypoints, origin, destination] = useMemo(() => {
        const waypoints = [...addresses];
        const destination = waypoints.pop();
        const origin = waypoints.shift();

        if (!destination) {
            throw new Error('No addresses defined');
        }

        return [waypoints, origin, destination];
    }, [addresses]);

    useEffect(() => {
        if (!map || origin) {
            return;
        }

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({
            address: formatAddress(destination),
        }, results => {
            if (results.length === 0) {
                return;
            }

            map.panTo(results[0].geometry.location);
            map.setZoom(17);
            setDestinationLocation(results[0].geometry.location);
        });
    }, [map, origin, destination]);

    useEffect(() => {
        if (!map || !origin || waypoints.length > 25) {
            return;
        }

        const directionsService = new google.maps.DirectionsService();
        directionsService.route(
            {
                origin: formatAddress(origin),
                destination: formatAddress(destination),
                travelMode: google.maps.TravelMode.DRIVING,
                waypoints: waypoints.length > 0 ? waypoints.map(address => ({
                    location: formatAddress(address),
                })) : undefined,
            },
            (result, status) => {
                if (status !== google.maps.DirectionsStatus.OK) {
                    setLoadError(true);
                    return;
                }

                setDirectionsResult(result);
            }
        );
    }, [map, origin, destination, waypoints, setLoadError, setDirectionsResult]);

    useEffect(() => {
        if (!map || origin || !navigator.geolocation) {
            return;
        }

        navigator.geolocation.getCurrentPosition(
            position => {
                const directionsService = new google.maps.DirectionsService();
                directionsService.route(
                    {
                        origin: {lat: position.coords.latitude, lng: position.coords.longitude},
                        destination: formatAddress(destination),
                        travelMode: google.maps.TravelMode.DRIVING,
                    },
                    (result, status) => {
                        if (status === google.maps.DirectionsStatus.OK) {
                            setDirectionsResult(result);
                        }
                    }
                );
            },
            () => {
                // Errors are ignored.
            },
            {enableHighAccuracy: true}
        );
    }, [map, origin, destination, setDirectionsResult]);

    const handleUnmount = useCallback(() => {
        setMap(null);
    }, [setMap]);

    const waypointLimitReached = useMemo(() => addresses.length > 27, [addresses.length]);

    const exitToApp = () => {
        if (!destination || !destinationLocation) {
            return;
        }

        const url = new URL('https://maps.google.com/maps');
        url.searchParams.set('ll', '');

        if (destinationLocation) {
            url.searchParams.set('daddr', `${destinationLocation.lat()},${destinationLocation.lng()}`);
        } else {
            url.searchParams.set('daddr', formatAddress(destination));
        }

        if (navigator.platform.match(/(iPhone|iPad|iPod)/)) {
            url.protocol = 'maps';
        }

        window.open(url.toString());
    };

    return (
        <Dialog fullScreen open={open}>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Route Map
                    </Typography>
                    {fallbackRouteMapUrl && !waypointLimitReached && !loadError && (
                        <IconButton
                            color="inherit"
                            onClick={() => setForceFallback(!forceFallback)}
                            edge={!origin && destination ? false : 'end'}
                        >
                            <SwitchIcon/>
                        </IconButton>
                    )}
                    {!origin && destination && (
                        <IconButton color="inherit" edge="end" onClick={exitToApp}>
                            <ExitToAppIcon/>
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>

            <div className={classes.container}>
                {(forceFallback || waypointLimitReached || loadError) && fallbackRouteMapUrl && (
                    <PinchZoomPan position="center" zoomButtons={false} maxScale={2}>
                        <img src={fallbackRouteMapUrl} className={classes.mapImage} alt="Route Map"/>
                    </PinchZoomPan>
                )}

                {!forceFallback && !waypointLimitReached && !loadError && (
                    <LoadScript
                        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                        onError={() => setLoadError(true)}
                    >
                        <GoogleMap
                            mapContainerClassName={classes.mapContainer}
                            center={initialCenter}
                            zoom={5}
                            onLoad={setMap}
                            onUnmount={handleUnmount}
                        >
                            {directionsResult ? (
                                <DirectionsRenderer options={{directions: directionsResult}}/>
                            ) : destinationLocation && (
                                <Marker position={destinationLocation}/>
                            )}

                            <DeviceLocation/>
                        </GoogleMap>
                    </LoadScript>
                )}
            </div>
        </Dialog>
    );
};

export default MapDialog;
