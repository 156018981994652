import {Circle, Marker} from '@react-google-maps/api';
import React, {useEffect, useState} from 'react';

const DeviceLocation : React.FC = () => {
    const [deviceCoords, setDeviceCoords] = useState<Coordinates | null>(null);

    useEffect(() => {
        if (!navigator.geolocation) {
            return;
        }

        const watchId = navigator.geolocation.watchPosition(
            position => setDeviceCoords(position.coords),
            () => {
                // Errors are ignored.
            },
            {enableHighAccuracy: true}
        );

        return () => {
            navigator.geolocation.clearWatch(watchId);
            setDeviceCoords(null);
        };
    }, [setDeviceCoords]);

    if (!deviceCoords) {
        return null;
    }

    const position = {lat: deviceCoords.latitude, lng: deviceCoords.longitude};

    return (
        <>
            <Marker
                position={position}
                clickable={false}
                draggable={false}
                cursor="pointer"
                icon={{
                    path: google.maps.SymbolPath.CIRCLE,
                    fillColor: '#4285f4',
                    fillOpacity: 1,
                    scale: 6,
                    strokeColor: 'white',
                    strokeWeight: 2,
                }}
                options={{
                    optimized: false,
                    zIndex: 3,
                }}
                title="Current location"
            />

            <Circle
                center={position}
                radius={deviceCoords.accuracy}
                draggable={false}
                options={{
                    fillColor: '#61a0bf',
                    fillOpacity: 0.4,
                    strokeColor: '#1bb6ff',
                    strokeOpacity: 0.4,
                    strokeWeight: 1,
                    zIndex: 1,
                }}
            />
        </>
    );
};

export default DeviceLocation;
