import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import {FallbackProps} from 'react-error-boundary';
import PaddedContainer from '../components/PaddedContainer';

const ErrorFallback : React.FC<FallbackProps> = ({resetErrorBoundary} : FallbackProps) => {
    return (
        <PaddedContainer>
            <Card>
                <CardHeader title="Whoops, an error occurred"/>
                <CardContent>
                    <Typography>An unexpected error occurred. Please reload the app.</Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" onClick={resetErrorBoundary}>Reload</Button>
                </CardActions>
            </Card>
        </PaddedContainer>
    );
};

export default ErrorFallback;
